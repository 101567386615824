#particules-js {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
  
  html, body {
    margin: 0;
    padding: 0;
}

body{
    background-image: url(../data/IA_BG_Reverse.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   
    z-index: -2;
}

a{
    color: #000;
    text-decoration: none;
}